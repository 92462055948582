import React from 'react'
import Fade from 'react-reveal/Fade';

function Experience (props) {

    const isMobile = props.isMobile;
    let points = [];

    function setupPoints() {
        for (const [key, value] of Object.entries(props.content.points)) {
            points.push(<p key={key} className={isMobile ? "mobile-experience-text" : "experience-text"}>{props.content.useBullets ? "•" : ""} {value}</p>);
        }
    }

    return (
        <div id={props.content.divId} className={isMobile ? "mobile-experience-container" : "experience-container"}>
            <div className="no-margins center-experience">
                <Fade>
                <h2 className={isMobile ? "no-margins mobile-experience-title" : "no-margins experience-title"}>{props.content.title}</h2>
                    <hr className="resume-mobile-line" style={isMobile ? {} : {display:"none"}}></hr>
                    <h4 className={isMobile ? "no-margins mobile-experience-subtitle" : "no-margins experience-subtitle"}>{props.content.subtitle}</h4>
                    <div>
                        {setupPoints()}
                        {points}
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default Experience;