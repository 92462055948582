import React, { useState } from 'react'
import Experience from '../components/resume-page/experience';
import ResumeScrollbar from '../components/resume-page/resume-scrollbar';
import SkillsSection from '../components/resume-page/skills-section';
import ResumePDF from '../components/resume-page/resume-pdf';

function ResumePage (props){
    // =========================================================================================================================
    // TODO: Make call to backend instead of using hard-coded content. Will handle any return value in the same format as below.
    // =========================================================================================================================

    const [scrollPosition, setScrollPosition] = useState(0);
    const isMobile=props.isMobile;
    let experiences = [];
    let divIds = [];

    function updateScrollPosition () {
        const element = document.getElementById("resume-page-wrapper");
        setScrollPosition(Math.round((element.scrollTop / element.clientHeight) + (0.085 * (element.scrollTop / element.clientHeight))));
    }

    function setupExperiences() {
        for (const [key, value] of Object.entries(content)) {
            experiences.push(<Experience content={value} isMobile={isMobile} key={key}/>);
            divIds.push(value.divId);
        }
    }

    const content = {
        pennStateExperience : {
            divId : "penn-state",
            title : "Penn State",
            subtitle : "Computer Science",
            useBullets: false,
            points : {
                point1 : "Expected Graduation: May 2022",
                point2 : "GPA: 3.45/4.00",
            }
        },
        hubspotExperience : {
            divId : "hubspot",
            title : "HubSpot",
            subtitle : "Software Engineering Intern",
            useBullets: true,
            points : {
                point1 : "Created many mission-critical features throughout the development of HubSpot’s Asset Marketplace.",
                point2 : "Reworked the mobile experience of HubSpot’s App Marketplace.",
                point3 : "Developed a suite of integration tests using Selenium for HubSpot’s App Marketplace.",
                point4 : "Technologies used: JavaScript, React, Redux, CSS",
            }
        },
        principalExperience : {
            divId : "principal",
            title : "Principal Finanical Group",
            subtitle : "Software Engineering Intern",
            useBullets: true,
            points : {
                point1 : "Deployed the first application to Amazon Web Services in the USIS Non-Qualified IT department.",
                point2 : "Laid the groundwork for other Non-Qualified IT teams to begin working with Amazon Web Services using Terraform.",
                point3 : "Built client-facing and internal pages in web applications using React and Angular with Java Spring Boot backends.",
                point4 : "Technologies used: JavaScript, Java, TypeScript, SQL, React, Angular, Spring Boot, Terraform, Amazon Web Services (S3 & Cloudfront)",
            }
        },
        capitalOneExperience : {
            divId : "capital-one",
            title : "Capital One Software Engineering Summit",
            subtitle : "Selected Participant",
            useBullets: true,
            points : {
                point1 : "Collaborated with a 5-person team to create a new platform that brings awareness to local fundraisers.",
                point2 : "Presented the project to a panel of distinguished Capital One employees and received 1st place recognition.",
                point3 : "Applied new concepts from learning sessions to a Hackathon project in order to gain practical experience.",
                point4 : "Technologies used: JavaScript, Python, Java, SQL, Flask, Amazon Web Services",
            }
        },
        leagueClubExperience : {
            divId : "league-club",
            title : "League of Legends Club at Penn State",
            subtitle : "Club President & Graphics Director",
            useBullets: true,
            points : {
                point1 : "Organize both online and in-person events for our community of 300+ League of Legends players.",
                point2 : "Lead the club by working with other officers to plan events, meetings, and other directives.",
                point3 : "Act as the primary point of contact for school administration for the club."
            }
        },
        esportsClubExperience : {
            divId : "esports-club",
            title : "Esports Club at Penn State",
            subtitle : "Graphics Chair",
            useBullets: true,
            points : {
                point1 : "Created exciting new marketing materials to attract students to the club’s Facebook page.",
                point2 : "Designed promotional graphics for club events such as Palentine’s Day and the Nittany Invitational.",
                point3 : "Produced stream graphics used during the Penn State Esports Club Nittany Lion Invitational live broadcast."
            }
        },
        discoverySpaceExperience : {
            divId : "discovery-space",
            title : "Discovery Space of Central Pennsylvania",
            subtitle : "Exhibit Floor Volunteer",
            useBullets: true,
            points : {
                point1 : "Guided students at Benner Elementary School through core mechanics concepts by building chain reactions.",
                point2 : "Inspired visitors to learn more about STEM-related fields through interactive exhibits.",
                point3 : "Resolved issues with exhibits to ensure a safe experience for guests."
            }
        },
        team341Experience : {
            divId : "team-341",
            title : "FIRST Robotics Team 341",
            subtitle : "Strategy Leader & Programmer",
            useBullets: true,
            points : {
                point1 : "Programmed a robot using Java and the WPILib library to operate either autonomously or by an operator.",
                point2 : "Used GRIP Vision processing for autonomous driving and to assist operators with precise movements.",
                point3 : "Developed a comprehensive strategy to succeed in FIRST Robotics Competition events.",
                point4 : "Java, WPILib, GRIP Vision Processing",
            }
        },
        teamHondurasExperience : {
            divId : "team-honduras",
            title : "FIRST Global Challenge Team Honduras",
            subtitle : "Team Mentor",
            useBullets: true,
            points : {
                point1 : "Helped the team overcome technological disadvantages when developing their first robot.",
                point2 : "Communicated remotely with a team of seven high school students in Honduras.",
                point3 : "Advised the students on ways they could improve their robot."
            }
        },
    }

    return (
        <div className="resume-page-wrapper" id="resume-page-wrapper" onScroll={updateScrollPosition}>
            <h1 className="resume-page-title" style={isMobile ? {display:"none"} : {}}>Resume</h1>
            {setupExperiences()}
            <ResumeScrollbar scrollPosition={scrollPosition} divIds={divIds}/>
            <br/><br/>
            {experiences}
            <SkillsSection isMobile={isMobile}/>
            <ResumePDF />
        </div>
    )
}

export default ResumePage;