import React from 'react'
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import resume from "../../resources/resume.pdf"

function ResumePDF (props) {
    // ========================================================================
    // TODO: Make call to backend for PDF instead of using hard-coded PDF link.
    // ========================================================================

    const isMobile = props.isMobile;

    return (
        <div id="pdf" className={isMobile ? "mobile-experience-container" : "experience-container"}>
                <div className="no-margins center-experience">
                <Fade>
                    <h4 className={isMobile ? "no-margins mobile-experience-subtitle" : "no-margins experience-subtitle"}>That's all for now!</h4>
                    <h5 className={isMobile ? "no-margins mobile-pdf-text" : "no-margins pdf-text"}><Link className="resume-pdf-link" to={resume} target="_blank" rel="noopener noreferrer">Click here to view a PDF version of my resume.</Link></h5>
                    <hr className="resume-mobile-line" style={isMobile ? {} : {display:"none"}}></hr>
                </Fade>
                </div>
            </div>
    )
}

export default ResumePDF;