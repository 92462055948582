import React, { useEffect, useState }  from 'react';
import './App.css';
import Navbar from './components/navbar';
import HomePage from './pages/home-page';
import { BrowserRouter as Router,  Switch, Route } from "react-router-dom";
import ProjectsPage from './pages/projects-page';
import ResumePage from './pages/resume-page';
import MobileNavbar from './components/mobile-navbar';

function App() {
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 1023;

  return (
    <div className="App">
      {!isMobile ? (
        <Router>
          <Navbar/>
          <div className="page-wrapper">
            <Switch>
              <Route path="/projects">
                <ProjectsPage isMobile={isMobile}/>
              </Route>
              <Route path="/resume">
                <ResumePage isMobile={isMobile}/>
              </Route>
              <Route path="/">
                <HomePage isMobile={isMobile}/>
              </Route>
            </Switch>
          </div>
        </Router>
      ) : (
        <div className="mobile-app">
          <Router>
          <MobileNavbar />
          <div className="mobile-page-wrapper">
            <Switch>
              <Route path="/projects">
                <ProjectsPage isMobile={isMobile}/>
              </Route>
              <Route path="/resume">
                <ResumePage isMobile={isMobile}/>
              </Route>
              <Route path="/">
                <HomePage isMobile={isMobile}/>
              </Route>
            </Switch>
          </div>
        </Router>
        </div>
      )} 
    </div>
  );
}

export default App;
