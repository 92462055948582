import React from 'react'
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle} from '@fortawesome/free-solid-svg-icons'
import { faCircle as hollowCircle} from '@fortawesome/free-regular-svg-icons'

function ResumeScrollbar (props) {

    const ids = props.divIds;
    let buttons = [];

    function setupButtons() {
        for (let i=0; i<ids.length;i++) {
            buttons.push(<div key={i}><a href={`#${ids[i]}`} className="experience-section-link"><FontAwesomeIcon icon={(props.scrollPosition === i ? faCircle : hollowCircle)} /></a><br/></div>);
        }
    }

    return (
        <Fade right>
            <div className="resume-navigation-wrapper">
                {setupButtons()}
                {buttons}
                <a href="#skills" className="experience-section-link"><FontAwesomeIcon icon={(props.scrollPosition === ids.length ? faCircle : hollowCircle)} /></a><br/>
                <a href="#pdf" className="experience-section-link"><FontAwesomeIcon icon={(props.scrollPosition === ids.length + 1 ? faCircle : hollowCircle)} /></a><br/>
            </div>
        </Fade>
    )
}

export default ResumeScrollbar;