import React from 'react'

function Project (props) {
    let isMobile = props.isMobile;
    let technologies = [];

    function setupTechnologies() {
        for (const [key, value] of Object.entries(props.content.technologies)) {
            technologies.push(<li key={key}>{value}</li>);
        }
    }

    return (
            <div className="project-wrapper" style={{width:(isMobile ? "100%" : "46%"), padding:(isMobile ? "5%" : "1%")}}>   
                <a className="project-link" href={props.content.link} target="_blank" rel="noopener noreferrer">
                    <img className="project-image" alt="" src={props.content.image}/>
                    <h2 className="project-title">{props.content.title}</h2>
                    <p className="project-text">{props.content.description}</p>
                    {setupTechnologies()}
                    <ul className="project-text">
                        {technologies}
                    </ul>
                </a>
            </div>
    )
}

export default Project;