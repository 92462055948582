import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faFileCode, faFile } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

function Navbar (props) {
    const [contentWidth, setContentWidth] = useState("0vw");
    const [whitespaceWidth, setWhitespaceWidth] = useState("0vw");
    const [navbarToggleTranslate, setNavbarToggleTranslate] = useState("-9vw");

    function toggleNavbar() {
        setContentWidth(contentWidth === "0vw" ? "18vw" : "0vw");
        setWhitespaceWidth(whitespaceWidth === "0vw" ? "80vw" : "0vw");
        setNavbarToggleTranslate(navbarToggleTranslate === "-9vw" ? "-9vw" : "-9vw");
    }

    return (
        <div className="navbar-wrapper" style={{width:(contentWidth === "0vw" ? "14vw" : "100vw")}}>
            <div className="navbar-content-wrapper" style={{width:contentWidth}}>
                <div className="navbar-content" style={{opacity:(whitespaceWidth === "0vw" ? "0%" : "100%")}}>
                    <h1 className="no-margins navbar-title">Connor<br/>Calderon</h1>
                    <h2><Link to="" className="navbar-link" onClick={() => toggleNavbar()}><FontAwesomeIcon icon={faHome}/> Home</Link><br/></h2>
                    <h2><Link to="/projects" className="navbar-link" onClick={() => toggleNavbar()}><FontAwesomeIcon icon={faFileCode}/> &nbsp;Projects</Link><br/></h2>
                    <h2><Link to="/resume" className="navbar-link" onClick={() => toggleNavbar()}><FontAwesomeIcon icon={faFile}/> &nbsp;Resume</Link><br/></h2>
                    <div className="navbar-social-links">
                        <h1 className="no-margins"><a href="https://www.linkedin.com/in/connor-calderon/" className="navbar-social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin}/> </a></h1>
                        <h1 className="no-margins"><a href="https://github.com/concal" className="navbar-social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub}/> </a></h1>
                        <h1 className="no-margins"><a href="https://twitter.com/c0ncal" className="navbar-social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter}/> </a></h1>
                        <h1 className="no-margins"><a href="https://www.instagram.com/connor.calderon/" className="navbar-social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram}/></a></h1>
                        <h4>&#169;2020 Connor Calderon</h4>
                    </div>
                </div>
            </div>
            {whitespaceWidth !== "80vw" ? (
                <div className="navbar-toggle bounce" onClick={() => toggleNavbar()}>
                    <h2 className="navbar-closed-icons" style={{right:"19%"}}>CC</h2><br/><br/>
                    <h2 className="navbar-closed-icons" style={{right:"18%"}}><FontAwesomeIcon icon={faHome}/></h2><br/><br/>
                    <h2 className="navbar-closed-icons" style={{right:"18%"}}><FontAwesomeIcon icon={faFileCode}/></h2><br/><br/>
                    <h2 className="navbar-closed-icons" style={{right:"17%"}}><FontAwesomeIcon icon={faFile}/></h2><br/><br/>
                </div>
            ) : ( 
                <div className="navbar-toggle bounce" onClick={() => toggleNavbar()}></div>
            )}
            <div className="navbar-whitespace" onClick={() => toggleNavbar()} style={{width:whitespaceWidth}}></div>
        </div>
    )
}

export default Navbar;