import React from 'react'

function HomePageSection (props) {

    // const isMobile = props.isMobile;

    return (
        <div className="home-page-section">
            <h2 className="home-page-section-title no-margins">{props.content.title}</h2>
            <div className="home-page-section-text no-margins" dangerouslySetInnerHTML={{ __html: props.content.paragraphs }}/>
        </div>
    )
}

export default HomePageSection;