import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as fullStar, faStarHalfAlt as halfStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons'
import Fade from 'react-reveal/Fade';

function SkillsSection (props) {
    // =========================================================================================================================
    // TODO: Make call to backend instead of using hard-coded content. Will handle any return value in the same format as below.
    // =========================================================================================================================

    const isMobile = props.isMobile;
    let skills = [],  levels = [];

    const content= {
        Java : 4,
        JavaScript : 4,
        React : 4,
        HTML : 4,
        CSS : 4,
        C : 3.5,
        Python : 3,
        Angular : 2.5,
        TypeScript : 2,
    }

    function setupSkills() {
        for (const [key, value] of Object.entries(content)) {
            skills.push(<p key={key} className={isMobile ? "mobile-experience-text" : "experience-text"}>{key}</p>);
            levels.push(<p key={key} className={isMobile ? "mobile-experience-text" : "experience-text"}>{getStars(value, key)}</p>);
        }
    }

    function getStars(count, key) {
        let stars = [];
        let keyCount = 0;
        for (count; count >= 1; count--) {
            stars.push(<FontAwesomeIcon key={`${key}-${keyCount}`} icon={fullStar}/>);
            keyCount++;
        }
        if(count !== 0) {
            stars.push(<FontAwesomeIcon key={`${key}-${keyCount}`} icon={halfStar}/>);
            keyCount++;
        }
        while(stars.length !== 5) {
            stars.push(<FontAwesomeIcon key={`${key}-${keyCount}`} icon={emptyStar}/>);
            keyCount++;
        }
        return stars;
    }

    return (
        <div id="skills" className={isMobile ? "mobile-experience-container" : "experience-container"}>
                <div className="no-margins center-experience">
                <Fade>
                    <h2 className={isMobile ? "no-margins mobile-experience-title" : "no-margins experience-title"}>Skills</h2>
                    <hr className="resume-mobile-line" style={isMobile ? {} : {display:"none"}}></hr>
                    <div className="resume-text" style={{width:"46%", textAlign:"right", float:"left"}}>
                        {setupSkills()}
                        {skills}
                    </div>
                    <div className="resume-text" style={{width:"52%", textAlign:"left", float:"right"}}>
                        {levels}
                    </div>
                </Fade>
                </div>
            </div>
    )
}

export default SkillsSection;