import React from 'react'
import Project from '../components/projects-page/project';
import paulCalderonImage from '../resources/paul-calderon-website.PNG'
import connorCalderonImage from '../resources/connor-calderon-website.PNG'
import Fade from 'react-reveal/Fade';

function ProjectsPage (props) {

    let isMobile = props.isMobile;
    let projects = [];

    const content = {
        paulCalderonWebsite : {
            image : paulCalderonImage,
            title : "Paul Calderon Personal Website",
            description : "This was my first time creating a react application completely from scratch. The initial iteration of this project felt very “early 2000’s”, so I have been working to make it feel more like a modern web application. As an example, the resume page looked like it was essentially just copied and pasted from a word document, and that is because it was for the most part just copied and pasted from a word document. I changed this by adding a feature that allows the user to select a company and view the experiences from that specific company, rather than just providing a long list of companies and experiences. I also added some animations to make the page feel more alive and engaging.",
            link : "https://www.johnpaulcalderon.com/",
            technologies : {
                javascript : "JavaScript",
                react : "React",
                html : "HTML",
                css : "CSS",
                netlify : "Netlify & GitHub",
            }
        },
        connorCalderonWebsite : {
            image : connorCalderonImage,
            title : "Connor Calderon Personal Website",
            description : "This is the website that you are on right now! This was my second time creating a React application from scratch. This time around, I felt much more familiar with what I was doing as I began the project, and I also did some extra work to draw out how I wanted each page to look before I even began programming. For this project, I wanted to see if I was able to create each page to be able to handle a back-end call, so all of the content that is seen on each page is stored in an object in the code, rather than being hard-coded into the HTML. These objects are all formatted in the way that I would send data from a backend, so that if I do even create a backend for this application, it is already programmed to handle it.",
            link : "https://concal.me/",
            technologies : {
                javascript : "JavaScript",
                react : "React",
                html : "HTML",
                css : "CSS",
                netlify : "Netlify & GitHub",
            }
        },
    }

    function setupProjects() {
        for (const [key, value] of Object.entries(content)) {
            projects.push(<Project content={value} isMobile={isMobile} key={key}/>);
        }
    }

    return (
        <div className={isMobile ? "mobile-projects-page-wrapper" : "projects-page-wrapper"}>
            <Fade>
            <div className="projects-page-content" style={{paddingTop:(isMobile ? "1vh" : "0px"), marginBottom:(isMobile ? "12vh" : "0px")}}>
                <h1 className="projects-page-title" style={isMobile ? {display:"none"} : {}}>Projects</h1>
                <div className="projects-wrapper">
                    {setupProjects()}
                    {projects}
                </div>
                <div className="more-projects-soon">
                    <h2 className="project-title">More Projects Coming Soon!</h2>
                </div>
            </div>
            </Fade>
        </div>
    )
}

export default ProjectsPage;