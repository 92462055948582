import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faFileCode, faFile, faBars } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

function MobileNavbar (props) {
    const [contentWidth, setContentWidth] = useState("0vw");
    const [whitespaceWidth, setWhitespaceWidth] = useState("0vw");
    const [navbarToggleWidth, setNavbarToggleWidth] = useState("30vw");

    function toggleNavbar() {
        setContentWidth(contentWidth === "0vw" ? "80vw" : "0vw");
        setWhitespaceWidth(whitespaceWidth === "0vw" ? "20vw" : "0vw");
        setNavbarToggleWidth(navbarToggleWidth === "0vw" ? "30vw" : "0vw");
    }

    return (
        <div className="navbar-wrapper" style={{width:(contentWidth === "0vw" ? "10vw" : "100vw")}}>
            <div className="navbar-content-wrapper" style={{width:contentWidth}}>
                <div className="mobile-navbar-content" style={{opacity:(navbarToggleWidth === "0vw" ? "100%" : "0%")}}>
                    <h1 className="no-margins navbar-title">Connor<br/>Calderon</h1>
                    <h2><Link to="" className="mobile-navbar-link" onClick={() => toggleNavbar()}><FontAwesomeIcon icon={faHome}/> Home</Link><br/></h2>
                    <h2><Link to="/projects" className="mobile-navbar-link" onClick={() => toggleNavbar()}><FontAwesomeIcon icon={faFileCode}/> &nbsp;Projects</Link><br/></h2>
                    <h2><Link to="/resume" className="mobile-navbar-link" onClick={() => toggleNavbar()}><FontAwesomeIcon icon={faFile}/> &nbsp;Resume</Link><br/></h2>
                    <div className="mobile-navbar-social-links">
                        <h1 className="no-margins"><a href="https://www.linkedin.com/in/connor-calderon/" className="navbar-social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin}/> </a></h1>
                        <h1 className="no-margins"><a href="https://github.com/concal" className="navbar-social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub}/> </a></h1>
                        <h1 className="no-margins"><a href="https://twitter.com/c0ncal" className="navbar-social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter}/> </a></h1>
                        <h1 className="no-margins"><a href="https://www.instagram.com/connor.calderon/" className="navbar-social-link" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram}/></a></h1>
                    </div>
                    <h6 style={{position:"fixed", bottom:"4%"}}>&#169;2020 Connor Calderon</h6>
                </div>
            </div>
            <div className="mobile-navbar-toggle mobile-bounce" onClick={() => toggleNavbar()} style={{width:navbarToggleWidth}}>
                <h2 className="mobile-navbar-hamburger no-margins"><FontAwesomeIcon icon={faBars}/></h2>
            </div>
            <div className="navbar-whitespace" onClick={() => toggleNavbar()} style={{width:whitespaceWidth}}></div>
        </div>
    )
}

export default MobileNavbar;